.component {
  position: relative;
}

.recommendationsContainerDiv {
  position: absolute;
  width: 100%;
  background: var(--fastdo-recommendation-text-field-background);
  border-radius: 0;
  max-height: 14rem;
  overflow-y: auto;
  margin-top: 1rem;
  border-radius: var(--fastdo-recommendation-text-field-border-radius);
  z-index: var(--fastdo-recommendation-text-field-z-index, 100);
}

.recomendationText {
  color: var(--fastdo-recommendation-text-field-color);
  font-size: var(--fastdo-recommendation-text-field-font-size);
  font-weight: var(--fastdo-recommendation-text-field-font-weight);
  margin: 0;
  padding: 0.6rem 2rem;
  text-align: start;
  cursor: pointer;
}

.recomendationText:hover {
  color: var(--fastdo-recommendation-text-field-color-hover);
  background: var(--fastdo-recommendation-text-field-background-hover);
}

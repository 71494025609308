.component {
  display: flex;
  flex-wrap: wrap;
}

.imgContainer {
  font-size: 0;
  width: 50%;
  padding: 4px;
}

.imgContainer img {
  width: 100%;
  border-radius: 8px;
  cursor: pointer;
  filter: brightness(75%);
}

.imgContainer img:hover {
  filter: brightness(100%);
  transition: 0.3s cubic-bezier(0.7, -0.09, 0.44, 1.09);
}

@media (max-width: 600px) {
  .component {
    flex-direction: column;
  }
  .imgContainer {
    width: 100%;
  }
}

.equipmentIcon {
  width: 2.5rem;
  height: 2.5rem;
  margin-right: 2rem;
  color: var(--fastdo-dark-color);
}

.accordionHeaderDiv {
  display: flex;
  align-items: center;
}

.component {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 600;
}

.shader {
  background-color: var(--fastdo-gray1-color);
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7));
  opacity: 0.5;
  z-index: 550;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}

.headerDiv {
  display: flex;
  align-items: center;
  justify-content: center;
}

.header {
  margin: 0.6rem 0 0.6rem 0;
  font-size: 1.4rem;
}

.closeIcon {
  position: absolute;
  right: 2rem;
  height: 26px;
  width: 26px;
  cursor: pointer;
  color: var(--primary-black-color);
}

@import "./app/app.css";
@import "./app/components.css";

* {
  box-sizing: border-box;
  font-family: var(--fastdo-primary-font-family);
}

body {
  margin: 0;
}

h1 {
  color: var(--fastdo-dark-color);
  font-size: var(--fastdo-heading1-font-size);
  font-weight: var(--fastdo-heading1-font-weight);
  margin: var(--fastdo-heading1-margin);
}

h2 {
  color: var(--fastdo-dark-color);
  font-size: var(--fastdo-heading2-font-size);
  font-weight: var(--fastdo-heading2-font-weight);
  margin: var(--fastdo-heading2-margin);
}

h3 {
  color: var(--fastdo-dark-color);
  font-size: var(--fastdo-heading3-font-size);
  font-weight: var(--fastdo-heading3-font-weight);
  margin: var(--fastdo-heading3-margin);
}

h4 {
  color: var(--fastdo-dark-color);
  font-size: var(--fastdo-heading4-font-size);
  font-weight: var(--fastdo-heading4-font-weight);
  margin: var(--fastdo-heading4-margin);
}

p {
  color: var(--fastdo-dark-color);
  font-size: var(--fastdo-body1-font-size);
  font-weight: var(--fastdo-body1-font-weight);
  margin: var(--fastdo-body1-margin);
  text-align: justify;
}

@media (max-width: 600px) {
  p {
    text-align: start;
  }
}

a {
  text-decoration: none;
}

ul {
  margin: var(--fastdo-ul-margin);
  padding: var(--fastdo-ul-padding);
}

ol {
  margin: var(--fastdo-ol-margin);
  padding: var(--fastdo-ol-padding);
}

div {
  font-size: var(--fastdo-body1-font-size);
  font-weight: var(--fastdo-body1-font-weight);
}

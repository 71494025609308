.component {
  text-align: start;
}

.component label {
  font-size: var(--fastdo-text-field-label-font-size, var(--fastdo-label-font-size));
  font-weight: var(--fastdo-text-field-label-font-weight, var(--fastdo-label-font-weight));
  margin: var(--fastdo-text-field-label-margin, 0 0 0 12px);
}

.component input {
  width: 100%;
  margin-top: 2px;
  border: 0;
  transition: 0.3s;
  font-size: var(--fastdo-text-field-input-font-size, var(--fastdo-body1-font-size));
  font-weight: var(--fastdo-text-field-input-font-weight, var(--fastdo-body1-font-weight));
  padding: var(--fastdo-text-field-input-padding, 10px, 12px);
  border-radius: var(--fastdo-text-field-input-border-radius, var(--fastdo-border-radius-2));
  color: var(--fastdo-text-field-input-color, var(--fastdo-dark-color));
  background: var(--fastdo-text-field-input-background, var(--fastdo-gray1-color));
}

.component input:focus {
  outline: none;
  box-shadow: var(--fastdo-text-field-box-shadow, var(--fastdo-box-shadow-focus-2));
}

.component input::-webkit-calendar-picker-indicator {
  cursor: pointer;
}

.underText {
  font-size: var(--fastdo-text-field-label-font-size, var(--fastdo-label-font-size));
  font-weight: var(--fastdo-text-field-label-font-weight, var(--fastdo-label-font-weight));
  margin: 2px 0 0 12px;
}

.componentDisabled input {
  background: var(--fastdo-text-field-input-background-disabled, var(--fastdo-gray2-color));
}

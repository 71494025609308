.homePageMainOutterDiv {
  min-height: 100vh;
}

.homePageMainDiv {
  padding: 8rem 8rem 0 8rem;
}

.headerDiv {
  max-width: 500px;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.headerDiv h1 {
  font-size: 4rem;
  margin: 0;
  animation-name: headerH1;
  animation-duration: 1.1s;
}

@keyframes headerH1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.headerDiv p {
  animation-name: headerP;
  animation-duration: 1.5s;
}

.toGalleryDiv {
  animation-name: headerP;
  animation-duration: 2.1s;
}

@keyframes headerP {
  0% {
    opacity: 0;
  }
  30% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.headerActionButtonsDiv {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  animation-name: headerButtonsDiv;
  animation-duration: 1.7s;
}

@keyframes headerButtonsDiv {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.iconsDiv {
  padding-top: 9rem;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.tentIcon {
  width: 400px;
  height: 400px;
  color: var(--fastdo-dark-color);
}

.fireIcon {
  width: 200px;
  height: 200px;
  color: var(--fastdo-dark-color);
}

@media (max-width: 1350px) {
  .headerDiv {
    max-width: 400px;
  }
}

@media (max-width: 1240px) {
  .tentIcon {
    width: 350px;
    height: 350px;
  }
}

@media (max-width: 1200px) {
  .homePageMainDiv {
    padding-right: 0;
  }
}

@media (max-width: 1100px) {
  .headerDiv {
    max-width: 600px;
    position: unset;
  }

  .headerDiv h1 {
    font-size: 4rem;
    margin: 0;
  }

  .iconsDiv {
    padding-top: 1rem;
    padding-left: 5rem;
    justify-content: start;
  }

  .tentIcon {
    width: 300px;
    height: 300px;
  }
}

@media (max-width: 990px) {
  .fireIcon {
    width: 150px;
    height: 150px;
  }
}

@media (max-width: 760px) {
  .homePageMainDiv {
    padding-left: 4rem;
  }

  .headerDiv {
    max-width: 500px;
  }

  .headerDiv h1 {
    font-size: 3rem;
    margin: 0;
  }

  .iconsDiv {
    padding-left: 2rem;
    padding-top: 5rem;
  }
}

@media (max-width: 620px) {
  .homePageMainDiv {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .headerDiv {
    width: 100%;
  }
}

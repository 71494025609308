.headerDiv {
  display: flex;
  align-items: center;
}

.headerDiv h2 {
  margin-left: 10px;
}

.navUl {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
}

.navUl li {
  padding: 0 0.6rem;
}

.navLink {
  display: block;
  text-decoration: none;
  padding: 12px 16px;
  border-radius: 8px;
  color: var(--fastdo-dark-color);
  transition: 0.3s;
}

.navLink:hover {
  background-color: var(--fastdo-dark-color);
  color: var(--fastdo-light-color);
}

.actionDiv {
  display: flex;
  flex-direction: column;
}

.profileLabel {
  margin: 0 0 2px 0;
  color: var(--fastdo-dark-color);
}

.actionLink {
  text-decoration: none;
  color: var(--fastdo-error-color);
}

@media (max-width: 1100px) {
  .navUl {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
  }
  .navUl li {
    margin: 0.2rem 0;
  }
  .profileLabel {
    padding: 2px 16px;
    margin-top: 1.2rem;
    margin-bottom: 0.2rem;
  }
  .actionLink {
    padding: 2px 16px;
    margin-top: 0.2rem;
    margin-bottom: 1.2rem;
  }
}

.itemRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 600px) {
  .itemRow {
    flex-direction: column;
    align-items: center;
  }
}

.icon {
  margin-left: 6px;
  width: 28px;
  height: 28px;
  color: var(--fastdo-primary-color);
}

.textIcon {
  padding: 0.4rem;
  background: var(--fastdo-primary-color);
  color: var(--fastdo-light-color);
  border-radius: 10px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
}

.googleMapsContainer {
  filter: brightness(85%);
  transition: filter 0.4s;
  font-size: 0;
}

.googleMapsContainer:hover {
  filter: brightness(100%);
  transition: filter 0.4s;
}

.component img {
  max-width: 100%;
}

.component pre {
  padding: 1rem;
  background-color: var(--secondary-background-color);
  border-radius: 1rem;
  color: var(--primary-white-color);
}

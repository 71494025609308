.component {
  width: 100%;
  padding: 0.8rem 2rem;
  z-index: 100;
  display: flex;
  align-items: center;
}

.componentMobile {
  width: 100%;
  padding: 0.8rem 2rem;
  z-index: 100;
  display: flex;
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.headerDiv {
}

.headerDivMobile {
  z-index: 110;
}

.navContainer {
  margin: auto;
}

.navContainerMobile {
  text-align: center;
}

.actionDiv {
}

.actionDivMobile {
  text-align: center;
}

.actionSlidingDivMobile {
  position: absolute;
  left: 0;
  width: 100%;
  background-color: coral;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: 0.3s;
}

.openCloseIconDiv {
  display: flex;
  z-index: 110;
}

.openMenuIcon {
  width: 42px;
  height: 42px;
  cursor: pointer;
}

.closeMenuIcon {
  width: 42px;
  height: 42px;
  cursor: pointer;
}

@keyframes showMenu {
  0% {
    top: -7rem;
  }
  100% {
    top: 0;
  }
}

@keyframes hideMenu {
  0% {
    top: 0;
  }
  100% {
    top: -7rem;
  }
}

.componentRelative {
  position: relative;
}

.componentAbsolute {
  position: absolute;
}

.componentFixed {
  position: fixed;
}

.componentFixedHiding {
  position: fixed;
}

.componentFixedHidingBigScreen {
}

.componentFixedHidingSmallScreen {
}

/*
.componentRelative {
  position: relative;
}

.componentAbsolute {
  position: absolute;
  background: transparent;
  top: 0;
}

.componentFlex {
  position: fixed;
  top: 0;
  background-color: var(--primary-background-color);
  animation: none;
}

.componentFlexHidden {
  position: fixed;
  top: 0;
  background-color: var(--primary-background-color);
  animation: none;
}

@keyframes showMenu {
  0% {
    top: -7rem;
  }
  100% {
    top: 0;
  }
}

@keyframes hideMenu {
  0% {
    top: 0;
  }
  100% {
    top: -7rem;
  }
}

.logoDiv {
  z-index: 100;
}

.iconDiv {
  z-index: 100;
  display: none;
}

.homeLogoImg {
  position: absolute;
}

.iconDiv svg {
  color: var(--primary-menu-color);
  width: 50px;
  height: 50px;
  cursor: pointer;
}

.component a {
  color: var(--primary-menu-color);
  text-decoration: none;
  transition: 0.4s;
}

.component ul {
  list-style-type: none;
  padding: 0;
  display: flex;
  align-items: center;
}

.component ul li {
  margin: 0 0.8rem;
  z-index: 200;
}

.component a:hover {
  color: var(--primary-color);
}

.closeMenuIcon {
  transition: 0.5s;
}

.closeMenuIcon:hover {
  transform: rotate(180deg);
  transition: 0.5s;
}

.languagesMobileLi {
  display: none;
}

@media (max-width: 1100px) {
  .component {
    padding: 1rem 2rem;
  }

  .component nav {
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    background-color: var(--primary-menu-background-color);
    z-index: 99;
    padding-top: 5rem;
    transition: 0.5s;
  }

  .component ul {
    flex-direction: column;
  }

  .component ul li {
    margin: 1rem 1rem;
  }

  .iconDiv {
    display: flex;
  }

  .languagesLi {
    display: none;
  }

  .languagesMobileLi {
    display: unset;
  }

  .componentFlex {
    animation: showMenu 0.3s;
  }

  .componentFlexHidden {
    top: -7rem;
    animation: hideMenu 0.3s;
  }
}

.languageCurrentP {
  cursor: pointer;
  margin: 0;
  color: var(--primary-menu-color);
}

.languagesContainerDiv {
  position: absolute;
  display: flex;
  flex-direction: column;
  padding: 0.8rem 0.8rem;
  box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%);
  border-radius: 4px;
  margin-top: 10px;
  left: -0.8rem;
}

.languagesContainerDiv p {
  margin: 0;
  cursor: pointer;
  transition: 0.4s;
}

.languagesContainerDiv p:hover {
  color: var(--primary-color);
}

.languagesSeparatorDiv {
  height: 10px;
}

.languagesContainerMobileDiv {
  display: flex;
}

.languagesContainerMobileDiv p {
  color: var(--primary-white-color);
  cursor: pointer;
  margin: 0;
  transition: 0.4s;
}

.languagesContainerMobileDiv p:hover {
  color: var(--primary-color);
}

.languagesSeparatorMobileDiv {
  width: 36px;
} */

.fastdo-heading1 {
  font-size: var(--fastdo-heading1-font-size);
  font-weight: var(--fastdo-heading1-font-weight);
}

.fastdo-heading2 {
  font-size: var(--fastdo-heading2-font-size);
  font-weight: var(--fastdo-heading2-font-weight);
}

.fastdo-heading3 {
  font-size: var(--fastdo-heading3-font-size);
  font-weight: var(--fastdo-heading3-font-weight);
}

.fastdo-body1 {
  font-size: var(--fastdo-body1-font-size);
  font-weight: var(--fastdo-body1-font-weight);
}

.fastdo-body2 {
  font-size: var(--fastdo-body2-font-size);
  font-weight: var(--fastdo-body2-font-weight);
}

.fastdo-label {
  font-size: var(--fastdo-label-font-size);
  font-weight: var(--fastdo-label-font-weight);
}

.fastdo-color-error {
  color: var(--fastdo-error-color);
}

.fastdo-color-success {
  color: var(--fastdo-success-color);
}

.fastdo-font-weight-bold {
  font-weight: 600;
}

.fastdo-label-link-1 {
  color: var(--fastdo-primary-color);
  font-size: var(--fastdo-label-font-size);
  font-weight: var(--fastdo-label-font-weight);
  text-decoration: none;
  cursor: pointer;
}

.fastdo-body1-link-1 {
  color: var(--fastdo-dark-color);
  font-size: var(--fastdo-body1-font-size);
  font-weight: var(--fastdo-body1-font-weight);
  text-decoration: none;
  cursor: pointer;
}

.fastdo-body1-link-1:hover {
  color: var(--fastdo-primary-color);
}

.fastdo-label-link-2 {
  color: var(--fastdo-secondary-color);
  font-size: var(--fastdo-label-font-size);
  font-weight: var(--fastdo-label-font-weight);
  text-decoration: none;
  cursor: pointer;
}

.component {
  background-color: var(--fastdo-gray1-color);
  border-radius: var(--fastdo-border-radius-3);
  padding-left: var(--fastdo-padding-2);
  padding-right: var(--fastdo-padding-2);
}

.option {
  padding: var(--fastdo-padding-2);
  display: flex;
  align-items: center;
}

.option input {
  margin: 0;
  cursor: pointer;
  accent-color: var(--fastdo-primary-color);
}

.option label {
  font-size: var(--fastdo-body1-font-size);
  margin-left: var(--fastdo-margin-1);
  cursor: pointer;
}

.component {
  position: fixed;
  max-width: 500px;
  bottom: 2rem;
  left: 2rem;
  z-index: 500;
  animation-name: fadeIn;
  animation-duration: var(--cookie-consent-fadein-duration);
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  70% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.buttonsContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.buttonSpacer {
  display: none;
  height: 10px;
}

@media (max-width: 600px) {
  .component {
    bottom: 0;
    left: 0;
    width: 100%;
    max-width: 100%;
  }
  .buttonsContainer {
    flex-direction: column;
  }
  .buttonSpacer {
    display: block;
  }
}

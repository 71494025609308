.component {
  background: var(--fastdo-primary-background);
  width: 100%;
  padding: 2rem 1rem;
  color: var(--fastdo-dark-color);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.component address {
  font-style: normal;
  text-align: center;
  font-size: var(--fastdo-body2-font-size);
}

.component p {
  font-size: var(--fastdo-body2-font-size);
}

.component a {
  text-decoration: none;
  color: var(--fastdo-primary-color);
}
